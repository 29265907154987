import React, { useState, useEffect } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import { Typography } from "@mui/material";
import "../styles/Timeline.css";
import data from "../content/timeline.json";

import thinkerthinkers from "../assets/tinkerthinkers.png";
import brightTalk from "../assets/brightTalk.png";
import smallhands from "../assets/littlehands.jpg";
import learningacademy from "../assets/learningacademy.jpg";
import rawabiAlsharqEnglish from "../assets/rawabiAlsharqEnglish.svg";
import rawabiAlsharqArabic from "../assets/rawabialsharqArbic.svg";
import logoLight from "../assets/logoLight.svg"; // Import the light version

// Map the image string names to the actual imported image files
const imageMap = {
  thinkerthinkers: thinkerthinkers,
  brightTalk: brightTalk,
  smallhands: smallhands,
  learningacademy: learningacademy,
  rawabiAlsharqEnglish: rawabiAlsharqEnglish,
  rawabiAlsharqArabic: rawabiAlsharqArabic,
};

const CustomTimeline = ({ language }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 786);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 786);
    };

    updateIsMobile();
    window.addEventListener("resize", updateIsMobile);

    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  const content = data[language];
  const isArabic = language === "ar";
  const arabicClass = isArabic ? "arabic-text" : "";

  return (
    <>
      {isMobile ? (
        <MobileTimeline
          content={content}
          arabicClass={arabicClass}
          isArabic={isArabic}
          isMobile={isMobile} // Pass the isMobile prop
        />
      ) : (
        <DesktopTimeline
          content={content}
          arabicClass={arabicClass}
          isArabic={isArabic}
        />
      )}
    </>
  );
};

const DesktopTimeline = ({ content, arabicClass, isArabic }) => {
  const timelineData = content.timelineItems;

  return (
    <div className={`timeline-container ${arabicClass}`}>
      <h2
        align="center"
        gutterBottom
        className={`desktop-banner-title-v2-big-title ${arabicClass}`}
      >
        {content.title}
      </h2>
      <Typography
        variant="body1"
        align="center"
        paragraph
        className={`desktop-banner-details-v2-title ${arabicClass}`}
      >
        {content.description}
      </Typography>
      <Timeline position="right">
        {timelineData.map((item, index) => (
          <TimelineItem key={index} className="timeline-item">
            <TimelineOppositeContent
              className="timeline-opposite-content"
              sx={{ m: "auto 0" }}
              align={isArabic ? "right" : "left"}
              variant="body2"
              color="text.secondary"
            >
              {item.image && (
                <img
                  src={imageMap[item.image]}
                  alt={item.title}
                  className="timeline-image"
                />
              )}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot
                color="primary"
                className={`timeline-dot`}
                sx={{ marginBottom: "10px", marginTop: "24px" }}
              />
              {index < timelineData.length && (
                <TimelineConnector className={`timeline-connector`} />
              )}
            </TimelineSeparator>
            <TimelineContent
              className={`timeline-content ${arabicClass}`}
              sx={{ py: "12px", px: 2 }}
            >
              <Typography variant="h6" component="span">
                <span className={`timeline-year ${arabicClass}`}>
                  {item.year}
                </span>
                <span
                  className={`timeline-title ${arabicClass}`}
                  style={{
                    marginRight: isArabic ? "10px" : "0px",
                    marginLeft: isArabic ? "0px" : "8px",
                  }}
                >
                  {item.title}
                </span>
              </Typography>

              <Typography
                className={`desktop-banner-details-v2-title ${arabicClass}`}
              >
                {item.text}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );
};

const MobileTimeline = ({ content, arabicClass }) => {
  const timelineData = content.timelineItems;

  return (
    <div className={`timeline-container mobile-timeline ${arabicClass}`}>
      {/* Our History Title */}
      <h2
        align="center"
        gutterBottom
        className={`mobile-banner-title-v2-big-title ${arabicClass}`}
      >
        {content.title}
      </h2>
      <Typography
        variant="body1"
        align="center"
        paragraph
        className={`mobile-banner-details-v2-title ${arabicClass}`}
      >
        {content.description}
      </Typography>

      {timelineData.map((item, index) => (
        <div key={index} className="mobile-timeline-item">
          <img
            src={
              item.image === "rawabiAlsharqEnglish" ||
              item.image === "rawabiAlsharqArabic"
                ? logoLight // Use the light logo on mobile
                : imageMap[item.image]
            }
            alt={item.title}
            className="timeline-image"
            style={{ width: "100%", height: "auto" }} // Ensure full logo visibility
          />
          <Typography
            variant="h6"
            component="div"
            className={`timeline-year ${arabicClass}`}
            align="center"
          >
            {item.year}
          </Typography>
          <Typography
            variant="h6"
            className={`timeline-title ${arabicClass}`}
            align="center"
          >
            {item.title}
          </Typography>
          <Typography className={`timeline-text ${arabicClass}`} align="center">
            {item.text}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default CustomTimeline;
