import React from "react";
import consultingData from "../content/consulting.json";
import "../styles/ConsultingSection.css";

const ConsultingSection = ({ language }) => {
  const content = consultingData[language];
  const isArabic = language === "ar";
  const arabicClass = isArabic ? "arabic-text rtl" : "";

  return (
    <section className={`consulting-section ${arabicClass}`}>
      <h1 className={`consulting-title-main ${arabicClass}`}>
        {language === "en" ? "Consulting" : "الاستشارات"}
      </h1>
      <div className="consulting-content">
        {Object.keys(content).map((key) => (
          <div key={key} className="consulting-item">
            <h2 className={`consulting-title ${arabicClass}`}>
              {content[key].title}
            </h2>
            <p className={`consulting-description ${arabicClass}`}>
              {content[key].description}
            </p>
            <p className={`consulting-additional-info ${arabicClass}`}>
              {content[key].additionalInfo}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ConsultingSection;
