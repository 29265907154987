import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Programs from "./pages/Programs";
import Services from "./pages/Services";
import NotFound from "./pages/NotFound"; // Import your 404 component

function App() {
  const [language, setLanguage] = useState("en");

  const contentStyle = {
    flex: 1,
    paddingTop: "0px", // Adjust this based on the navbar height
  };

  // Custom hook to scroll to top on route change
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navbar language={language} setLanguage={setLanguage} />
        <div id="content" style={contentStyle}>
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home language={language} />} />
            <Route path="/about" element={<About language={language} />} />
            <Route
              path="/services"
              element={<Services language={language} />}
            />
            <Route
              path="/programs"
              element={<Programs language={language} />}
            />
            {/* Add other routes here */}

            {/* Catch-all route for undefined paths (404 page) */}
            <Route path="*" element={<NotFound language={language} />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
