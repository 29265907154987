import React, { useEffect, useState } from "react";
import "../styles/ProgramsDetails.css";
import content from "../content/programsDetails.json";
import program1ImageEN from "../assets/Program1.png";
import program2ImageEN from "../assets/Program2.png";
import program1ImageAR from "../assets/Program1.png";
import program2ImageAR from "../assets/Program2.png";

const ProgramsDetails = ({ language }) => {
  const [lang, setLang] = useState(language || "en");

  useEffect(() => {
    setLang(language);
  }, [language]);

  if (!content || !content[lang]) {
    return (
      <section
        className={`programs-details-section ${lang === "ar" ? "rtl" : "ltr"}`}
      >
        <div className="horizontal-line"></div>
        <h2 className="details-title">Content Not Available</h2>
        <div className="horizontal-line"></div>
      </section>
    );
  }

  const currentContent = content[lang];
  const { title, programs } = currentContent;

  const imagesEN = [program1ImageEN, program2ImageEN];
  const imagesAR = [program1ImageAR, program2ImageAR];
  const images = lang === "ar" ? imagesAR : imagesEN;

  return (
    <section
      className={`programs-details-section ${lang === "ar" ? "rtl" : "ltr"}`}
    >
      <div className="horizontal-line"></div>
      <h2 className="details-title">{title}</h2>
      <div className="programs-list">
        {programs.map((program, index) => (
          <div key={index} className="program-item">
            <div className={`program-content ${lang === "ar" ? "rtl" : ""}`}>
              {lang === "ar" && (
                <img
                  src={images[index]}
                  alt={`Program ${index + 1}`}
                  className="program-image"
                />
              )}
              <div className="program-description-wrapper">
                <h3 className="program-title">{program.title}</h3>
                <p className="program-description">{program.description}</p>
              </div>
              {lang === "en" && (
                <img
                  src={images[index]}
                  alt={`Program ${index + 1}`}
                  className="program-image"
                />
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="horizontal-line"></div>
    </section>
  );
};

export default ProgramsDetails;
