import React, { useEffect, useState } from "react";
import "../styles/ContentSection.css"; // Adjust the path as necessary
import content from "../content/programsIntro.json"; // Adjust the path as necessary

const ProgramsIntro = ({ language }) => {
  const [lang, setLang] = useState(language || "en");

  useEffect(() => {
    setLang(language);
  }, [language]);

  // Check if content is defined before accessing lang
  if (!content || !content[lang]) {
    return (
      <section className={`content-section ${lang === "ar" ? "rtl" : ""}`}>
        <div className="horizontal-line"></div>
        <h2 className="content-title">Content Not Available</h2>
        <div className="horizontal-line"></div>
      </section>
    );
  }

  const currentContent = content[lang];
  const { title, introduction } = currentContent;

  return (
    <section className={`content-section ${lang === "ar" ? "rtl" : ""}`}>
      <div className="horizontal-line"></div>
      <h2 className="content-title">{title}</h2>
      {introduction && <p className="content-paragraph">{introduction}</p>}
      <div className="horizontal-line"></div>
    </section>
  );
};

export default ProgramsIntro;
