import React, { useState, useEffect, useRef, useCallback } from "react";
import "../styles/Testimonial.css"; // CSS styles
import data from "../content/parentsTestimonials.json"; // JSON content
import leftarrow from "../assets/arrow-left-circle-fill.svg";
import rightarrow from "../assets/arrow-right-circle-fill.svg";

function TestimonialSection({ language = "en" }) {
  const [testimonials, setTestimonials] = useState([]);
  const [disclaimer, setDisclaimer] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselInterval = useRef(null);

  const startCarousel = useCallback(() => {
    stopCarousel(); // Clear any existing interval
    carouselInterval.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 10000); // Change testimonial every 10 seconds
  }, [testimonials.length]);

  const stopCarousel = () => {
    if (carouselInterval.current) {
      clearInterval(carouselInterval.current);
    }
  };

  useEffect(() => {
    // Set testimonials and disclaimer based on the selected language
    setTestimonials(data.testimonials[language]);
    setDisclaimer(data.disclaimer[language]);

    // Start automatic carousel
    startCarousel();

    // Cleanup interval on component unmount
    return () => {
      stopCarousel();
    };
  }, [language, startCarousel]);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div
      className="testimonial-section"
      onMouseEnter={stopCarousel}
      onMouseLeave={startCarousel}
    >
      <h2
        className="testimonial-title"
        lang={language === "ar" ? "ar" : "en"}
        style={{
          fontFamily:
            language === "ar"
              ? "Noto Kufi Arabic, sans-serif"
              : "Inter, sans-serif",
        }}
      >
        {language === "ar" ? "شهادات أولياء الأمور" : "Parents Testimonials"}
      </h2>

      <div className="carousel-wrapper">
        <div className="carousel-controls">
          <img
            src={leftarrow}
            alt="Previous"
            onClick={prevTestimonial}
            className="arrow-button"
          />
        </div>

        <div
          className="testimonial-item"
          lang={language === "ar" ? "ar" : "en"}
          style={{
            fontFamily:
              language === "ar" ? "Noto Kufi Arabic, sans-serif" : "inherit",
          }}
        >
          {testimonials.length > 0 ? (
            <p>{testimonials[currentIndex]}</p>
          ) : (
            <p>Loading testimonials...</p>
          )}
        </div>

        <div className="carousel-controls">
          <img
            src={rightarrow}
            alt="Next"
            onClick={nextTestimonial}
            className="arrow-button"
          />
        </div>
      </div>

      <div className="carousel-indicators">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`indicator ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>

      <div className="disclaimer" lang={language === "ar" ? "ar" : "en"}>
        <p>{disclaimer}</p>
      </div>
    </div>
  );
}

export default TestimonialSection;
