import React from "react";

const NotFound = ({ language }) => {
  // Define text for both languages
  const text = {
    en: {
      title: "404 - Page Not Found",
      message: "Oops! The page you're looking for doesn't exist.",
    },
    ar: {
      title: "٤٠٤ - الصفحة غير موجودة",
      message: "عذرًا! الصفحة التي تبحث عنها غير موجودة.",
    },
  };

  // Define inline styles, considering both languages
  const containerStyle = {
    textAlign: "center",
    padding: "50px",
    fontFamily:
      language === "ar" ? "Noto Kufi Arabic, sans-serif" : "Inter, sans-serif",
    direction: language === "ar" ? "rtl" : "ltr",
  };

  const titleStyle = {
    fontSize: "36px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#333",
  };

  const messageStyle = {
    fontSize: "20px",
    color: "#666",
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>{text[language].title}</h1>
      <p style={messageStyle}>{text[language].message}</p>
    </div>
  );
};

export default NotFound;
