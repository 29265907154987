import React from "react";
import WordTransition from "../components/WordTransition";
import TimelineSection from "../components/TimelineSection"; // Import the TimelineSection component
import WhatWeDo from "../components/WhatWeDo";

const About = ({ language }) => {
  return (
    <div>
      <WordTransition language={language} />
      <WhatWeDo language={language} />
      <TimelineSection language={language} />{" "}
      {/* Add the TimelineSection here */}
    </div>
  );
};

export default About;
