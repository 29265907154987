import React, { useState, useEffect, useRef, useCallback } from "react";
import sentencesData from "../content/wordtransition.json";
import "../styles/WordTransition.css";
import leftarrow from "../assets/arrow-left-circle-fill.svg";
import rightarrow from "../assets/arrow-right-circle-fill.svg";

const WordTransition = ({ language }) => {
  const sentences = sentencesData[language] || sentencesData["en"];
  const [currentSentence, setCurrentSentence] = useState(0);
  const [animationKey, setAnimationKey] = useState(0);
  const elapsedTimeRef = useRef(0);
  const intervalRef = useRef(null);
  const animationDuration = 7000; // 7 seconds for each sentence

  const isArabic = language === "ar";
  const arabicClass = isArabic ? "arabic-text rtl" : "";

  const updateCircleProgress = (elapsed) => {
    const elapsedTimePercentage = elapsed / animationDuration;
    const degrees = elapsedTimePercentage * 360;
    document.querySelector(
      ".countdown-circle"
    ).style.background = `conic-gradient(#0579ce ${degrees}deg, #ffffff ${degrees}deg)`;
  };

  const startTimer = useCallback(
    (resume = false) => {
      clearInterval(intervalRef.current); // Clear any existing interval

      if (!resume) {
        elapsedTimeRef.current = 0; // Reset elapsed time when starting fresh
        updateCircleProgress(0); // Reset the progress circle
      }

      intervalRef.current = setInterval(() => {
        elapsedTimeRef.current += 100; // Increment every 100ms

        if (elapsedTimeRef.current >= animationDuration) {
          // Move to the next sentence when the animation duration is complete
          setCurrentSentence((prev) => (prev + 1) % sentences.length);
          elapsedTimeRef.current = 0;
          updateCircleProgress(0);
        } else {
          updateCircleProgress(elapsedTimeRef.current);
        }
      }, 100); // Update every 100ms
    },
    [sentences.length, animationDuration]
  );

  const resetTimer = () => {
    setAnimationKey((prev) => prev + 1); // Force the word transition to re-render
    clearInterval(intervalRef.current); // Stop the current interval
    startTimer(); // Start a fresh timer
  };

  const pauseTimer = () => {
    clearInterval(intervalRef.current); // Stop the interval
  };

  const resumeTimer = () => {
    startTimer(true); // Resume with elapsed time
  };

  useEffect(() => {
    startTimer(); // Start the timer on mount
    return () => clearInterval(intervalRef.current); // Clean up on unmount
  }, [startTimer]);

  const handlePrev = () => {
    pauseTimer(); // Pause before changing the sentence
    setCurrentSentence((prev) =>
      prev === 0 ? sentences.length - 1 : prev - 1
    );
    resetTimer(); // Reset all states when navigating
  };

  const handleNext = () => {
    pauseTimer(); // Pause before changing the sentence
    setCurrentSentence((prev) => (prev + 1) % sentences.length);
    resetTimer(); // Reset all states when navigating
  };

  const handleIndicatorClick = (index) => {
    pauseTimer(); // Pause before changing the sentence
    setCurrentSentence(index);
    resetTimer(); // Reset all states when navigating
  };

  const handleMouseEnter = () => {
    pauseTimer(); // Pause everything on hover
  };

  const handleMouseLeave = () => {
    resumeTimer(); // Resume from where left off on unhover
  };

  const handleCircleClick = () => {
    resetTimer(); // Reset all states when clicking on the circle
  };

  return (
    <section
      className={`word-transition-hero-section ${arabicClass}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="word-transition-banner-v2-content-wrap">
        <div className="word-transition-banner-v2-details-wrap">
          <div
            className="countdown-circle"
            onClick={handleCircleClick}
            aria-label="Progress circle, click to reset"
          ></div>
          <h1 className={`word-transition-banner-title-v2-big ${arabicClass}`}>
            {isArabic ? "نحن هنا" : "We are here"}&nbsp;
            <span
              key={animationKey}
              className={`word-transition-banner-title-v2-small ${arabicClass}`}
              style={{
                animation: "wordTransitionFadeInUp 1s ease-in-out",
                opacity: 1,
              }}
            >
              {sentences[currentSentence]}
            </span>
          </h1>
        </div>
      </div>
      <div className="word-transition-controls">
        <img
          src={leftarrow}
          alt={isArabic ? "Next" : "Previous"} // Swap labels in Arabic
          className="arrow left-arrow"
          onClick={isArabic ? handleNext : handlePrev} // Swap functionality in Arabic
          aria-label={isArabic ? "Next sentence" : "Previous sentence"}
        />
        <div className="indicators">
          {sentences.map((_, index) => (
            <span
              key={index}
              className={`indicator ${
                index === currentSentence ? "active" : ""
              }`}
              onClick={() => handleIndicatorClick(index)}
              aria-label={`Indicator ${index + 1}`}
            ></span>
          ))}
        </div>
        <img
          src={rightarrow}
          alt={isArabic ? "Previous" : "Next"} // Swap labels in Arabic
          className="arrow right-arrow"
          onClick={isArabic ? handlePrev : handleNext} // Swap functionality in Arabic
          aria-label={isArabic ? "Previous sentence" : "Next sentence"}
        />
      </div>
    </section>
  );
};

export default WordTransition;
