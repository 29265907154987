import React from "react";
import trainingData from "../content/training.json";
import "../styles/TrainingSection.css";

const TrainingSection = ({ language }) => {
  const content = trainingData[language];
  const isArabic = language === "ar";
  const arabicClass = isArabic ? "arabic-text rtl" : "";

  return (
    <section className={`training-section ${arabicClass}`}>
      <h1 className={`training-title-main ${arabicClass}`}>
        {content.trainingAndLectures.title}
      </h1>
      <p className={`training-description ${arabicClass}`}>
        {content.trainingAndLectures.description}
      </p>
      <div className="training-content">
        {Object.keys(content).map(
          (key) =>
            key !== "trainingAndLectures" && (
              <div key={key} className="training-item">
                <h2 className={`training-title ${arabicClass}`}>
                  {content[key].title}
                </h2>
                <ul className={`training-description ${arabicClass}`}>
                  {content[key].description.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
              </div>
            )
        )}
      </div>
    </section>
  );
};

export default TrainingSection;
