import React from "react";
import "../styles/Footer.css";
import linkedinIcon from "../assets/linkedin.svg";
import facebookIcon from "../assets/facebook.svg";
import instagramIcon from "../assets/instagram.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="social-links">
          <a
            href="https://www.linkedin.com/company/rawabi-al-sharq"
            target="_blank"
            rel="noopener noreferrer" // Comment outside JSX
            aria-label="LinkedIn"
            className="social-button linkedin"
          >
            <img src={linkedinIcon} alt="LinkedIn" className="social-icon" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100057568205421"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
            className="social-button facebook"
          >
            <img src={facebookIcon} alt="Facebook" className="social-icon" />
          </a>
          <a
            href="https://www.instagram.com/rawabi_al_sharq?igsh=MXNkN3pna2s1Ym9tMQ%3D%3D&utm_source=qr"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
            className="social-button instagram"
          >
            <img src={instagramIcon} alt="Instagram" className="social-icon" />
          </a>
        </div>
        <div className="contact-info">
          <p>
            Reach us at:{" "}
            <a href="mailto:info@rawabialsharq.com">info@rawabialsharq.com</a>
          </p>
        </div>
        <div className="copyright-info">
          <p>
            © {new Date().getFullYear()} Rawabi Al Sharq. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
