import React, { useEffect, useState } from "react";
import "../styles/ContentSection.css"; // Adjust the path as necessary

const ContentSection = ({ content, language }) => {
  const [lang, setLang] = useState(language || "en");

  useEffect(() => {
    setLang(language);
  }, [language]);

  const currentContent = content[lang];

  // Check if currentContent is defined
  if (!currentContent) {
    return (
      <section className={`content-section ${lang === "ar" ? "rtl" : ""}`}>
        <div className="horizontal-line"></div>
        <h2 className="content-title">Content Not Available</h2>
        <div className="horizontal-line"></div>
      </section>
    );
  }

  const { title, ...paragraphs } = currentContent;

  return (
    <section className={`content-section ${lang === "ar" ? "rtl" : ""}`}>
      <div className="horizontal-line"></div>
      <h2 className="content-title">{title}</h2>
      {Object.keys(paragraphs).map((key) => (
        <p key={key} className="content-paragraph">
          {paragraphs[key]}
        </p>
      ))}
      <div className="horizontal-line"></div>
    </section>
  );
};

export default ContentSection;
