import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import navbarContent from "../content/navbar.json";
import threedots from "../assets/threedots.svg";
import closeIcon from "../assets/closeIcon.svg";
import logo from "../assets/logoLight.svg";
import "../styles/navbar.css";

const Navbar = ({ language, setLanguage }) => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState("home");
  const [content, setContent] = useState(navbarContent.en);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  useEffect(() => {
    setContent(navbarContent[language]);
  }, [language]);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 786);
    };

    window.addEventListener("resize", updateIsMobile);

    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  useEffect(() => {
    const currentPath = location.pathname.split("/")[1] || "home"; // Default to 'home' for root
    setActiveItem(currentPath);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector("nav");
      if (window.scrollY > 0) {
        navbar.classList.add("nav-shadow");
      } else {
        navbar.classList.remove("nav-shadow");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLanguageChange = (newLanguage) => {
    // Save the current scroll position
    const currentScrollPosition = window.pageYOffset;

    // Update the language without scrolling to the top
    setLanguage(newLanguage);

    // Restore the scroll position after language change
    setTimeout(() => {
      window.scrollTo(0, currentScrollPosition);
    }, 0);
  };

  const handleNavClick = (key) => {
    setActiveItem(key);
  };

  return (
    <nav
      className={isMobile ? "navbar mobile-navbar" : "navbar desktop-navbar"}
    >
      <div className="container">
        <Logo onClick={() => handleNavClick("home")} />
        {isMobile ? (
          <MobileNavbar
            content={content}
            activeItem={activeItem}
            handleNavClick={handleNavClick}
            setLanguage={handleLanguageChange}
            language={language}
          />
        ) : (
          <DesktopNavbar
            content={content}
            activeItem={activeItem}
            handleNavClick={handleNavClick}
            setLanguage={handleLanguageChange}
            language={language}
          />
        )}
        <LanguageSelector
          language={language}
          setLanguage={handleLanguageChange}
        />
      </div>
    </nav>
  );
};

const Logo = ({ onClick }) => (
  <div className="logo-container">
    <Link to="/home" onClick={() => onClick("home")}>
      <img src={logo} alt="Logo" className="logo logo-image" />
    </Link>
  </div>
);

const LanguageSelector = ({ language, setLanguage }) => (
  <div className="language-toggle">
    <span
      className={language === "en" ? "active-language" : ""}
      onClick={() => setLanguage("en")}
    >
      EN
    </span>
    {" | "}
    <span
      className={language === "ar" ? "active-language" : ""}
      onClick={() => setLanguage("ar")}
    >
      AR
    </span>
  </div>
);

const DesktopNavbar = ({ content, activeItem, handleNavClick, language }) => {
  const navItems = Object.keys(content.navItems).sort(
    (a, b) => content.navOrder.indexOf(a) - content.navOrder.indexOf(b)
  );

  const displayedNavItems = language === "ar" ? navItems.reverse() : navItems;

  return (
    <ul className="nav-links">
      {displayedNavItems.map((key) => (
        <li key={key} className="nav-item">
          <Link
            to={`/${key}`}
            className={`link desktop-nav-item ${
              activeItem === key ? "active-link" : ""
            } ${language === "ar" ? "arabic-text" : ""}`}
            onClick={() => handleNavClick(key)}
          >
            {content.navItems[key]}
          </Link>
        </li>
      ))}
    </ul>
  );
};

const MobileNavbar = ({ content, activeItem, handleNavClick, language }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className="mobile-header">
        <div
          className={`menu-icon ${menuOpen ? "menu-icon-open" : ""}`}
          onClick={toggleMenu}
        >
          <img
            src={menuOpen ? closeIcon : threedots}
            alt="Menu"
            className="icon-image"
          />
        </div>
      </div>
      <div
        className="overlay"
        style={{
          display: menuOpen ? "block" : "none",
          animation: menuOpen
            ? "slideDownMenu 0.3s ease forwards"
            : "slideUpMenu 0.3s ease forwards",
        }}
      >
        <ul
          className="nav-links-open"
          style={{
            textAlign: language === "ar" ? "right" : "left",
          }}
        >
          {Object.keys(content.navItems).map((key) => (
            <li key={key} className="nav-item">
              <Link
                to={`/${key}`}
                className={`link-mobile mobile-nav-item ${
                  activeItem === key ? "active-link-mobile" : ""
                } ${language === "ar" ? "arabic-text" : ""}`}
                onClick={() => {
                  handleNavClick(key);
                  setMenuOpen(false);
                }}
              >
                {content.navItems[key]}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Navbar;
