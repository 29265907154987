import React from "react";
import ServicesSection from "../components/ServicesSection.js";
import ConsultingSection from "../components/ConsultingSection.js";
import TrainingSection from "../components/trainingSection.js";

const Services = ({ language }) => {
  return (
    <div>
      <ServicesSection language={language} />
      <ConsultingSection language={language} />
      <TrainingSection language={language} />
    </div>
  );
};

export default Services;
