import React from "react";
import CountUp from "react-countup";
import "../styles/MetricsSection.css"; // Assuming you save your CSS here

const MetricsSection = ({ language }) => {
  const metrics = [
    {
      number: 65000,
      labelEn: "Students impacted",
      labelAr: "طالبًا استفادوا من خدماتنا",
    },
    {
      number: 24,
      labelEn: "Educational institutes",
      labelAr: "مؤسسة تعليمية تعاوننا معها",
    },
    {
      number: 20,
      labelEn: "Years of experience",
      labelAr: "عامًا من الخبرة في المجال",
    },
  ];

  const isArabic = language === "ar";

  return (
    <div className={`metrics-section ${isArabic ? "rtl" : ""}`}>
      {metrics.map((metric, index) => (
        <React.Fragment key={index}>
          <div className="metric-item">
            <span className="metric-number">
              <CountUp
                end={metric.number}
                duration={3} // Slower animation duration
                separator=","
                start={0}
                formattingFn={
                  isArabic ? (num) => num.toLocaleString("ar-EG") : undefined
                }
              />
              <span className="metric-plus">+</span>
            </span>
            <p className={`metric-label ${isArabic ? "arabic-text" : ""}`}>
              {isArabic ? metric.labelAr : metric.labelEn}
            </p>
          </div>
          {index < metrics.length - 1 && (
            <div className="metric-separator">|</div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default MetricsSection;
