import React from "react";
import HeroSection from "../components/HeroSection";
import LogoCarousel from "../components/LogoCarousel"; // Make sure this import is correct
import WhyUsSection from "../components/WhyUsSection";
import TestimonialSection from "../components/TestimonialSection"; // Import the TestimonialSection component
import MetricsSection from "../components/MetricsSection";
const Home = ({ language }) => {
  return (
    <div>
      <HeroSection language={language} />
      <LogoCarousel />
      <MetricsSection language={language} />
      <WhyUsSection language={language} />
      <TestimonialSection language={language} />
    </div>
  );
};

export default Home;
