import React from "react";
import servicesData from "../content/services.json"; // Import your JSON file
import "../styles/ServicesSection.css"; // Import your CSS file

import behavior from "../assets/behavior.png";
import speech from "../assets/speech.png";
import training from "../assets/training.png";
import workshops from "../assets/workshops.png";
import consulting from "../assets/consulting.png";
import inclusion from "../assets/inclusion.png";

// Map logo names to imported images
const logos = {
  "behavior.png": behavior,
  "speech.png": speech,
  "training.png": training,
  "workshops.png": workshops,
  "consulting.png": consulting,
  "inclusion.png": inclusion,
};

const ServicesSection = ({ language }) => {
  const services = servicesData.services; // Access the services array

  return (
    <section className={`services-section ${language === "ar" ? "rtl" : ""}`}>
      <div className="services-content-wrap">
        <h2
          className={`services-title ${language === "ar" ? "arabic-text" : ""}`}
        >
          {language === "ar" ? "خدماتنا" : "Our Services"}
        </h2>
        <div className="services-cards">
          {services.map((service, index) => (
            <div key={index} className="service-card">
              <img
                src={logos[service.logo]}
                alt="Service Icon"
                className="service-icon"
              />
              <h3
                className={`service-title ${
                  language === "ar" ? "arabic-text" : ""
                }`}
              >
                {language === "ar" ? service.point.ar : service.point.en}
              </h3>
              <p
                className={`service-description ${
                  language === "ar" ? "arabic-text" : ""
                }`}
              >
                {language === "ar" ? service.text.ar : service.text.en}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
