import React from "react";
import WhyOurPrograms from "../components/WhyOurPrograms";
import ProgramsIntro from "../components/ProgramsIntro";
import ProgramsDetails from "../components/ProgramsDetails";

const Programs = ({ language }) => {
  return (
    <div>
      <ProgramsIntro language={language} />
      <WhyOurPrograms language={language} />
      <ProgramsDetails language={language} />
    </div>
  );
};

export default Programs;
